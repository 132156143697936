$color-dark-blue: #04479E;
$color-dark-translucent: rgba(8, 15, 15, 0.5);
$color-dark: #080F0F;
$color-error: #D7263D;
$color-light-blue: #0197F6;
$color-light: #FBFBFF;

$breakpoint-large: 1024px;
$breakpoint-medium: 768px;
$breakpoint-small: 480px;

$gradient: linear-gradient(to bottom right, $color-dark, $color-dark-translucent);
