form {
  flex-direction: column;
  align-items: stretch;
  row-gap: 1em;
  width: 100%;
  margin-bottom: 2em;
  display: flex;
}

form.invalid input:invalid, form.invalid textarea:invalid {
  border: 2px solid #d7263d;
}

form.invalid .input-container:has(#name:invalid):after {
  content: "Please enter a name";
  color: #d7263d;
  margin-top: .5em;
  font-size: .75em;
}

form.invalid .input-container:has(#email:invalid):after {
  content: "Please enter a valid email address";
  color: #d7263d;
  margin-top: .5em;
  font-size: .75em;
}

form.invalid .input-container:has(#phone:invalid):after {
  content: "Please enter a valid phone number";
  color: #d7263d;
  margin-top: .5em;
  font-size: .75em;
}

form.invalid .input-container:has(#message:invalid):after {
  content: "Please enter a message";
  color: #d7263d;
  margin-top: .5em;
  font-size: .75em;
}

form .input-container {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

form label {
  margin-bottom: .5em;
}

form input, form textarea {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  color: #080f0f;
  font-family: inherit;
  font-size: inherit;
  background-color: #fbfbff;
  border: none;
  border-radius: .75em;
  width: 100%;
  padding: .75em;
}

form button {
  -webkit-appearance: none;
  appearance: none;
  color: #fbfbff;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  background-color: #0197f6;
  border: none;
  border-radius: .75em;
  width: 100%;
  padding: .75em;
}

.toast {
  box-sizing: border-box;
  background-color: #080f0f;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2em;
  transition: transform .3s;
  display: flex;
  position: fixed;
  bottom: 0;
  transform: translateY(100%);
}

.toast.show {
  transform: translateY(0);
}

.toast.error {
  background-color: #d7263d;
}

body {
  color: #080f0f;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #fbfbff;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Montserrat-Variable, sans-serif;
  display: flex;
}

header {
  box-sizing: border-box;
  color: #fbfbff;
  background-color: #04479e;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 2em 3em;
  display: flex;
  position: fixed;
}

header .title {
  margin: 0;
  font-size: 2em;
}

header .nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

header .nav-list li:not(:last-of-type) {
  margin: 0 2em 0 0;
}

header .nav-list a {
  color: #fbfbff;
  text-decoration: none;
}

header .menu-toggle, header .menu-icon-open, header .menu-icon-close {
  display: none;
}

@media (max-width: 768px) {
  header {
    padding: 1em 2em;
  }

  header .title {
    font-size: 1.5em;
  }

  header .nav {
    box-sizing: border-box;
    background-color: #080f0f;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding: 1em 2em;
    transition: transform .3s;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  header .nav-list {
    flex-direction: column;
  }

  header .nav-list li:not(:last-of-type) {
    margin: 0 0 2em;
  }

  header .menu-toggle:checked + .nav {
    transform: translateX(0);
  }

  header .menu-icon-open {
    cursor: pointer;
    font-size: 2em;
    display: block;
  }

  header .menu-icon-close {
    cursor: pointer;
    margin-bottom: 1em;
    font-size: 2em;
    display: block;
  }
}

footer {
  box-sizing: border-box;
  color: #fbfbff;
  background-color: #080f0f;
  width: 100vw;
  padding: 2em 3em;
}

@media (max-width: 768px) {
  footer {
    padding: 1em 2em;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.gradient-text {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(to right, #0197f6 0%, #90dbff 25%, #ef9afb 50%, #ffc2b2 75%, #ff7365 100%) 0 0 / 200%;
  -webkit-background-clip: text;
  background-clip: text;
  animation: 10s infinite gradient-animation;
}

.custom-list {
  flex-direction: column;
  row-gap: 1em;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.custom-list li {
  align-items: center;
  display: flex;
}

.custom-list li h2 {
  margin-top: 0;
}

.custom-list li img.custom-list-bullet {
  width: 3em;
  height: 3em;
  margin-right: 2em;
}

.logo-list {
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 1em;
  margin: 2em 0 0;
  padding: 0;
  display: flex;
}

.logo-list li {
  white-space: pre-wrap;
  text-align: center;
  flex-direction: column;
  flex-basis: 25%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.logo-list li img {
  width: auto;
  height: 6em;
}

@media (max-width: 768px) {
  .logo-list {
    row-gap: 1em;
  }

  .logo-list li {
    flex-basis: 33%;
  }

  .logo-list li img {
    width: auto;
    height: 4em;
  }
}

.segment {
  justify-content: center;
  align-items: center;
  display: flex;
}

.segment p {
  line-height: 1.5em;
}

.segment .segment-content {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  padding: 3em;
  display: flex;
}

@media (max-width: 768px) {
  .segment .segment-content {
    max-width: 100%;
    padding: 2em;
  }
}

#intro {
  background-blend-mode: overlay;
  color: #fbfbff;
  text-wrap: balance;
  background-image: url("pexels-fauxels-3183183.b379ad35.jpg"), linear-gradient(to bottom right, #080f0f, #080f0f80);
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  height: 100vh;
}

#intro .segment-content {
  align-items: start;
  max-width: none;
}

#intro .intro-title {
  margin-bottom: 0;
  font-size: 5em;
}

#intro .intro-subtitle {
  margin-top: .5em;
  font-size: 3em;
}

#intro .intro-button {
  -webkit-appearance: none;
  appearance: none;
  color: #fbfbff;
  cursor: pointer;
  background-color: #0197f6;
  border: none;
  border-radius: .75em;
  margin-top: .5em;
  padding: .5em 1em;
  font-family: inherit;
  font-size: 2em;
  font-weight: 600;
}

@media (max-width: 768px) {
  #intro .intro-title {
    font-size: 3em;
  }

  #intro .intro-subtitle {
    font-size: 2em;
  }

  #intro .intro-button {
    font-size: 1.5em;
  }
}

#tech {
  background-blend-mode: overlay;
  color: #fbfbff;
  background-image: url("tech.17fec7d5.jpg"), linear-gradient(to bottom right, #080f0f, #080f0f80);
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
}

#contact {
  background-blend-mode: overlay;
  color: #fbfbff;
  background-image: url("pexels-fauxels-3184291.b2257620.jpg"), linear-gradient(to bottom right, #080f0f, #080f0f80);
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  flex-direction: column;
  display: flex;
}

/*# sourceMappingURL=index.2911fb72.css.map */
