form {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  row-gap: 1em;
  width: 100%;

  &.invalid {
    input, textarea {
      &:invalid {
        border: 2px solid $color-error;
      }
    }

    .input-container:has(#name:invalid) {
      &::after {
        content: 'Please enter a name';
        color: $color-error;
        font-size: .75em;
        margin-top: .5em;
      }
    }

    .input-container:has(#email:invalid) {
      &::after {
        content: 'Please enter a valid email address';
        color: $color-error;
        font-size: .75em;
        margin-top: .5em;
      }
    }

    .input-container:has(#phone:invalid) {
      &::after {
        content: 'Please enter a valid phone number';
        color: $color-error;
        font-size: .75em;
        margin-top: .5em;
      }
    }

    .input-container:has(#message:invalid) {
      &::after {
        content: 'Please enter a message';
        color: $color-error;
        font-size: .75em;
        margin-top: .5em;
      }
    }
  }

  .input-container {
    align-items: stretch;
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: .5em;
  }

  input, textarea {
    appearance: none;
    background-color: $color-light;
    border-radius: .75em;
    border: none;
    box-sizing: border-box;
    color: $color-dark;
    font-family: inherit;
    font-size: inherit;
    padding: .75em;
    width: 100%;
  }

  button {
    appearance: none;
    background-color: $color-light-blue;
    border-radius: .75em;
    border: none;
    color: $color-light;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    padding: .75em;
    width: 100%;
  }
}

.toast {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $color-dark;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  transform: translateY(100%);
  transition: transform 0.3s ease;

  &.show {
    transform: translateY(0);
  }

  &.error {
    background-color: $color-error;
  }
}