@import "variables";
@import "contact-form";

body {
  background-color: $color-light;
  color: $color-dark;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat-Variable', sans-serif;
  height: 100vh;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

header {
  align-items: center;
  background-color: $color-dark-blue;
  box-sizing: border-box;
  color: $color-light;
  display: flex;
  justify-content: space-between;
  padding: 2em 3em;
  position: fixed;
  width: 100vw;

  .title {
    margin: 0;
    font-size: 2em;
  }

  .nav-list {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  .nav-list li:not(:last-of-type) {
    margin: 0 2em 0 0;
  }

  .nav-list a {
    color: $color-light;
    text-decoration: none;
  }

  .menu-toggle {
    display: none;
  }

  .menu-icon-open {
    display: none;
  }

  .menu-icon-close {
    display: none;
  }

  /* Mobile Styles */
  @media (max-width: $breakpoint-medium) {
    padding: 1em 2em;

    .title {
      font-size: 1.5em;
    }

    .nav {
      position: fixed;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background-color: $color-dark;
      box-sizing: border-box;
      flex-direction: column;
      padding: 1em 2em;
      transform: translateX(100%);
      transition: transform 0.3s ease;
    }

    .nav-list {
      flex-direction: column;
    }

    .nav-list li:not(:last-of-type) {
      margin: 0 0 2em 0;
    }

    .menu-toggle:checked + .nav {
      transform: translateX(0);
    }

    .menu-icon-open {
      cursor: pointer;
      display: block;
      font-size: 2em;
    }

    .menu-icon-close {
      cursor: pointer;
      display: block;
      font-size: 2em;
      margin-bottom: 1em;
    }
  }
}

footer {
  background-color: $color-dark;
  box-sizing: border-box;
  color: $color-light;
  padding: 2em 3em;
  width: 100vw;

  /* Mobile Styles */
  @media (max-width: $breakpoint-medium) {
    padding: 1em 2em;
  }
}

// Gradient text animation
@keyframes gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.gradient-text {
  background: linear-gradient(to right, #0197F6 0%, #90DBFF 25%, #EF9AFB 50%, #FFC2B2 75%, #FF7365 100%);
  background-size: 200% auto;
  animation: gradient-animation 10s ease infinite;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.custom-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  row-gap: 1em;

  li {
    display: flex;
    align-items: center;

    h2 {
      margin-top: 0;
    }

    img.custom-list-bullet {
      width: 3em;
      height: 3em;
      margin-right: 2em;
    }
  }
}

.logo-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2em 0 0;
  padding: 0;
  row-gap: 1em;

  li {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    white-space: pre-wrap;
    align-items: center;
    text-align: center;
    flex-basis: 25%;

    img {
      height: 6em;
      width: auto;
    }
  }

  @media (max-width: $breakpoint-medium) {
    row-gap: 1em;

    li {
      flex-basis: 33%;
      
      img {
        height: 4em;
        width: auto;
      }
    }
  }
}

.segment {
  align-items: center;
  display: flex;
  justify-content: center;

  p {
    line-height: 1.5em;
  }

  .segment-content {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: $breakpoint-medium;
    padding: 3em;

    @media (max-width: $breakpoint-medium) {
      max-width: 100%;
    }
  }

  @media (max-width: $breakpoint-medium) {
    .segment-content {
      padding: 2em;
    }
  }
}

#intro {
  background-blend-mode: overlay;
  background-image: url("../assets/photos/pexels-fauxels-3183183.jpg"), $gradient;
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  color: $color-light;
  height: 100vh;
  text-wrap: balance;

  .segment-content {
    align-items: start;
    max-width: none;
  }

  .intro-title {
    font-size: 5em;
    margin-bottom: 0;
  }

  .intro-subtitle {
    font-size: 3em;
    margin-top: .5em;
  }

  .intro-button {
    appearance: none;
    background-color: $color-light-blue;
    border-radius: .75em;
    border: none;
    color: $color-light;
    cursor: pointer;
    font-family: inherit;
    font-size: 2em;
    font-weight: 600;
    margin-top: .5em;
    padding: .5em 1em;
  }

  @media (max-width: $breakpoint-medium) {
    .intro-title {
      font-size: 3em;
    }

    .intro-subtitle {
      font-size: 2em;
    }

    .intro-button {
      font-size: 1.5em;
    }
  }
}

#tech {
  background-image: url("../assets/photos/tech.jpg"), $gradient;
  background-repeat: no-repeat, no-repeat;
  background-position: center, center;
  background-size: cover, cover;
  background-blend-mode: overlay;
  color: $color-light;
}

#contact {
  background-image: url("../assets/photos/pexels-fauxels-3184291.jpg"), $gradient;
  background-repeat: no-repeat, no-repeat;
  background-position: center, center;
  background-size: cover, cover;
  background-blend-mode: overlay;
  color: $color-light;
  display: flex;
  flex-direction: column;
}
